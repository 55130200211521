import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useFetcher } from '@/compose/axios'
//import { useFetcher } from '@/compose/axios'

const STORE_NAME = 'order'
const LOCALSTORAGE_ORDER_DATA = 'orderData'
const LOCALSTORAGE_ORDER_ID = 'order_id'
const LOCALSTORAGE_MAIL_CAR = 'mailCar'
const LOCALSTORAGE_FLEET = 'fleet'
const LOCALSTORAGE_TOUR = 'tour'

export const useOrderStore = defineStore(STORE_NAME, () => {
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
  const defaultData = {
    pickup: null,
    dropoff: null,
    hours: null,
    email: null,
    type_of_service: null,
    website: import.meta.env.VITE_PROJECT_URL,
    reqs: null,
    date_start: null,
    first_name: null,
    last_name: null,
    notes: null,
    phone: null,
    car: null,
    status: 2,
    code: null,
    extra_kms: null,
    payment_code: null,
    payment_date: null,
    consulting: null,
    country_prefix: null,
    total: null,
    distance: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null,
    redis_id: null,
    deal_id: null,
    lead_id: null,
    redirectStep: null,
    full_url: null,

    transaction_id: null,
    contact_id: null,
    allowedPages: {
      vehicle: 0,
      contact: 0,
      success_payment_intent: 0,
      success: 0
    },
    fromStart: null,
    ride_history: null,
    paymentSuccess: false
  }

  const orderData = ref(defaultData)
  const orderType = ref(localStorage.getItem('orderType') || 'NEW')
  const orderId = ref(null)
  const order = ref(null)
  const fleet = ref(null)
  const mailCar = ref(null)
  const tour = ref(null)
  const fromStayWithUs = ref(false)
  function changeTypeOrder(type) {
    orderType.value = type
    localStorage.setItem('orderType', orderType.value)
  }
  function update(data) {
    orderData.value = { ...orderData.value, ...data }
    sessionStorage.setItem(LOCALSTORAGE_ORDER_DATA, JSON.stringify(orderData.value))
  }

  async function updateStorage(data) {
    orderData.value = { ...orderData.value, ...data }
    return axiosInstance.post('orders/cache/create', orderData.value).then((response) => {
      return response
    })
  }

  function updateOrder(data) {
    order.value = data
    sessionStorage.setItem('order', JSON.stringify(order.value))
  }

  function updateFleet(data) {
    fleet.value = data
    sessionStorage.setItem(LOCALSTORAGE_FLEET, JSON.stringify(fleet.value))
  }

  function updateTour(data) {
    tour.value = data
    sessionStorage.setItem(LOCALSTORAGE_TOUR, JSON.stringify(tour.value))
  }

  function updateOrderId(data) {
    orderId.value = data
    sessionStorage.setItem(LOCALSTORAGE_ORDER_ID, orderId.value)
  }

  function updateMailCar(data) {
    mailCar.value = data
  }

  function $reset() {
    orderData.value = defaultData
    orderId.value = null
    mailCar.value = null
    fleet.value = null
    tour.value = null
    sessionStorage.setItem(LOCALSTORAGE_ORDER_DATA, JSON.stringify(orderData.value))
    sessionStorage.removeItem(LOCALSTORAGE_ORDER_ID)
    sessionStorage.removeItem(LOCALSTORAGE_FLEET)
    sessionStorage.removeItem(STORE_NAME)
    sessionStorage.removeItem(LOCALSTORAGE_MAIL_CAR)
    sessionStorage.removeItem(LOCALSTORAGE_TOUR)
  }

  function $resetOrder() {
    order.value = null
    sessionStorage.removeItem(STORE_NAME)
  }

  function $resetOrderId() {
    orderId.value = null
    sessionStorage.removeItem(LOCALSTORAGE_ORDER_ID)
  }

  const loadDefaultValue = () => {
    const localStorageData = sessionStorage.getItem(LOCALSTORAGE_ORDER_DATA)
    const sessionStorageOrderIdData = sessionStorage.getItem(LOCALSTORAGE_ORDER_ID)
    if (localStorageData) {
      update(JSON.parse(localStorageData))
    } else {
      update(defaultData)
    }

    if (sessionStorageOrderIdData) {
      updateOrderId(sessionStorageOrderIdData)
    } else {
      updateOrderId(null)
    }
  }

  loadDefaultValue()

  return {
    orderType,
    fromStayWithUs,
    orderData,
    orderId,
    mailCar,
    fleet,
    tour,
    updateMailCar,
    updateStorage,
    updateFleet,
    updateTour,
    update,
    updateOrder,
    updateOrderId,
    changeTypeOrder,
    $resetOrder,
    $resetOrderId,
    $reset
  }
})
