<template>
  <Form
    @submit="oneWaySubmit"
    class="form"
    ref="oneWayTransferForm"
    :validation-schema="oneWaySchema"
    v-slot="{ errors, isSubmitting }"
  >
    <span v-if="isSubmitting">
      {{ errorFill(errors) }}
    </span>

    <div
      v-if="!utils.isEmpty(errorOnForm) && isShowModal"
      @click="closeModal"
      class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-70 p-4"
    >
      <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
        <div class="flex justify-end p-4">
          <button
            @click="closeModal"
            aria-label="close"
            class="closeButton inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400"
            type="button"
          >
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- <div class="p-6 pt-0">
          <p v-for="error in errors">{{ error }}</p>
        </div> -->
        <div class="p-6 pt-0">
          <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
        </div>
        <div class="border-t border-gray-600 p-6">
          <button
            @click="closeModal"
            type="button"
            class="bg-main text-background rounded-[37px] px-5 py-2.5 text-center"
          >
            Ok
          </button>
        </div>
      </div>
    </div>

    <div :class="['home_form home_form-1', { active: true }]">
      <Field name="pickup" v-slot="{ field }" v-model="orderData.pickup">
        <TrustyComplete
          v-model="pickupRef"
          class="w-full md:w-[calc(100%/3-8px)]"
          classname="input !py-[22px] m-0 !w-full"
          @get:suggestions="fetchSuggestions"
          @update:suggestions="updateSuggestions"
          @clear:input="clearInput"
          @select:suggestions="selectSuggestions"
          :autoCompleteString="orderData.pickup"
          :suggestions="data"
          v-bind="field"
          placeholder="Pick Up Location*"
        />
      </Field>
      <Field name="dropoff" v-slot="{ field }" v-model="orderData.dropoff">
        <TrustyComplete
          v-model="dropoffRef"
          class="w-full md:w-[calc(100%/3-8px)]"
          classname="input !py-[22px] m-0 !w-full"
          @get:suggestions="fetchSuggestions"
          @update:suggestions="updateSuggestions"
          @clear:input="clearInput"
          @select:suggestions="selectSuggestions"
          :autoCompleteString="orderData.dropoff"
          :suggestions="data"
          v-bind="field"
          fieldType="dropoff"
          placeholder="Drop off Location*"
        />
      </Field>
      <Field name="date_start" v-slot="{ field }" v-model="datePicker">
        <VueDatePicker
          ref="datePickerRef"
          :teleport-center="ssid"
          class="input m-0 text-sm md:text-lg"
          placeholder="Date / Time*"
          :class="errors.date_start ? 'error' : ''"
          :preview-format="dateFormat"
          auto-apply
          partial-flow
          :clearable="false"
          :format="dateFormat"
          :dark="mode === 'dark'"
          :light="mode === 'light'"
          v-bind="field"
          v-model="datePicker"
          @internal-model-change="handleInternal"
          :min-date="minDate"
          :min-time="minTime"
        >
          <template #right-sidebar>
            <div v-if="datePicker" class="overflow-y-scroll px-3 py-2" style="max-height: 300px">
              <div
                v-for="time in timeOptions"
                :key="time"
                @click="setTime(time)"
                :class="[
                  'time-option cursor-pointer',
                  time === selectedTime ? 'time-selected' : ''
                ]"
              >
                {{ time }}
              </div>
            </div>
          </template>
        </VueDatePicker>
      </Field>
      <div
        class="route_toggle inline-flex h-[64.4px] w-full min-w-[98px] cursor-pointer items-center justify-center rounded-full bg-white md:h-[69.8px] md:w-auto"
        @click="toggleRouteMap"
      >
        <LocationIcon :active="pathHasPickupAndDropOff" />
      </div>
    </div>
    <div class="next_step_button_wrapper m-0">
      <button
        type="submit"
        class="next_step_button"
        :disabled="isSubmitting"
        @change="oneWaySubmit"
      >
        Next
      </button>
    </div>
    <RouteMap
      v-if="showRouteMap"
      :pickupRef="pickupRef"
      :form="oneWayTransferForm"
      :dropoffRef="dropoffRef"
      @markerdragged="markerDraggedHandler"
    />
  </Form>
</template>

<script setup>
import RouteMap from '@/components/RouteMap.vue'
import LocationIcon from '@/components/icons/LocationIcon.vue'
import TrustyComplete from '@/components/custom/TrustyComplete.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { Field, Form } from 'vee-validate'

import moment from 'moment'
import * as yup from 'yup'

import { computed, inject, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { useDatePicker } from '@/compose/datePicker'
import { useTrustyStore } from '@/stores/trustyComplete'
import { useMainStore } from '@/stores/main'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { useCentrifugoStore } from '@/stores/centrifugo'

const oneWayTransferForm = ref(null)

const selectedTime = ref('')

const trustyStore = useTrustyStore()

const { dropoffRef, pickupRef, data, pathStartFinish } = storeToRefs(trustyStore)
const { fetchSuggestions, selectSuggestions, updateSuggestions, clearInput } = trustyStore

const {
  datePickerRef,
  minDate,
  minTime,
  datePicker,
  timeOptions,
  dateFormat,
  handleInternal,
  initialDate,
  timeSetFirstTime
} = useDatePicker()

const router = useRouter()
const utils = inject('utils')
const regexLink = inject('regexLink')
const regexIsHttps = inject('regexIsHttps')

const showRouteMap = ref(false)
const isMapLoading = ref(false)
const isShowModal = ref(true)
const errorOnForm = ref(null)
const markersMoved = ref({
  pickup: false,
  dropoff: false
})

const mainStore = useMainStore()
const orderStore = useOrderStore()
const { orderData, orderId, orderType } = storeToRefs(orderStore)
const { mode, flow, ssid, isRequesting } = storeToRefs(mainStore)

const setTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  if (initialDate.value) {
    let updatedDate = new Date(initialDate.value)
    updatedDate.setHours(hours, minutes)
    datePicker.value = updatedDate
    orderData.value.date_start = moment(updatedDate).format('YYYY-MM-DD HH:mm')
  }
  selectedTime.value = time

  datePickerRef.value.closeMenu()
}

const centrifugoStore = useCentrifugoStore()

const pathHasPickupAndDropOff = computed(() => {
  return pathStartFinish.value.valid.pickup && pathStartFinish.value.valid.dropoff
})

const errorFill = (errorBag) => {
  errorOnForm.value = errorBag
}

const markerDraggedHandler = (locationType) => {
  markersMoved.value[locationType] = true
}

watch(errorOnForm, (newVal) => {
  if (!utils.isEmpty(newVal)) {
    isShowModal.value = true
  }
})

function closeModal() {
  isShowModal.value = false
}

const toggleRouteMap = () => {
  if (isMapLoading.value) return
  showRouteMap.value = !showRouteMap.value
}

watch(
  () => [pathStartFinish.value.valid.pickup, pathStartFinish.value.valid.dropoff],
  (updatedFieldValues) => {
    const updatedPickupValid = updatedFieldValues[0]
    const updatedDropoffValid = updatedFieldValues[1]

    showRouteMap.value = !(
      !updatedPickupValid ||
      !updatedDropoffValid ||
      Object.keys(pathStartFinish.value).length <= 1
    )
  }
)

const oneWaySchema = yup.object({
  pickup: yup
    .string()
    .required('Pick up is a required field')
    .max(230)
    .test(
      'location-restricted',
      'The airport is located at:  Amerigo Vespucci Airport, Via del Termine, Florence, Metropolitan City of Florence, Italy',
      (value) => {
        return !value.toString().includes('Amerigo Vespucci Airport, Viale Belfiore')
      }
    )
    .test(
      'google-complete',
      'Address must be verified using Google Autocomplete, and the country must be within the European Union or Switzerland',
      () => {
        // TODO: create Auto Submit place google autocomplete
        if (orderData.value.ride_history) return true
        if (orderData.value.fleet) return true
        //
        return pathStartFinish.value.valid.pickup
      }
    )
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),

  dropoff: yup
    .string()
    .required('Drop Off is a required field')
    .max(230, 'Drop Off should not exceed 230 character')
    .test(
      'location-restricted',
      'The airport is located at:  Amerigo Vespucci Airport, Via del Termine, Florence, Metropolitan City of Florence, Italy',
      (value) => {
        return !value.toString().includes('Amerigo Vespucci Airport, Viale Belfiore')
      }
    )
    .test(
      'google-complete',
      'Address must be verified using Google Autocomplete, and the country must be within the European Union or Switzerland',
      () => {
        // TODO: create Auto Submit place google autocomplete
        if (orderData.value.ride_history) return true
        if (orderData.value.fleet) return true
        //
        return pathStartFinish.value.valid.dropoff
      }
    )
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  date_start: yup
    .string()
    .required('Date is a required field')
    .max(230, 'Date should not exceed 230 character')
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true })
})

const oneWaySubmit = (values) => {
  values.date_start = moment(values.date_start).format('YYYY-MM-DD HH:mm')
  orderStore.update(values)
  orderData.value.allowedPages['contact'] = 1
  if (flow.value === 'mainsite') {
    isRequesting.value = true
    orderStore
      .updateStorage({
        ...values,
        ssid: ssid.value,
        type_of_service: 'oneWayTransfer',
        status: 2
      })
      .then((response) => {
        console.log(response)
        const socketData = {
          event: 'fill_form',
          cache_id: response.data.data.id
        }
        centrifugoStore.send('dli-' + ssid.value, socketData)
        isRequesting.value = false
      })
  }
  if (orderType.value === 'DUPLICATE') {
    router.push('/vehicle')
  } else if (flow.value !== 'mainsite') {
    router.push('/contact')
  }
}

onMounted(() => {
  if (pathStartFinish.value.valid.pickup && pathStartFinish.value.valid.dropoff) {
    showRouteMap.value = true
  }

  if (orderData.value.date_start) {
    timeSetFirstTime.value = false
    datePicker.value = new Date(orderData.value.date_start)
  }
  if (orderId.value !== null) {
    //ToDo: Make valid fields with google address query for get coords place
    // fields.value.pickup.valid = true
    // fields.value.dropoff.valid = true
    //orderStore.$reset()
    //mainStore.$reset()
    //datePicker.value = null
  }
  orderStore.update({ status: 2, type_of_service: 'oneWayTransfer' })
})

onBeforeMount(() => {})
</script>
